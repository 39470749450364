@import url(https://fonts.cdnfonts.com/css/neue-haas-grotesk-display-pro);
@import url(https://fonts.cdnfonts.com/css/crimson-text);
@import url(https://fonts.cdnfonts.com/css/crimson-text);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.App {
  font-family: 'Neue Haas Grotesk Display Pro', sans-serif;
  background: #ebe6d3;
  color: #83106a;
  padding-bottom: 2em;
}

.logo {
  font-size: xx-large;
  font-weight: bold;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.main {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.top-bar {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 50px;
}

.social-link {
  padding: 10px;
  font-family: 'Crimson Text', serif;
}

.home-section {
  height: 100vh;
  width: 80%;
  position: relative;
  text-align: center;
}

.footer {
  font-size: 6vmax;
  font-weight: 600;
  padding: 1em;
}

body::-webkit-scrollbar {
  width: 10px; /* width of the entire scrollbar */
}

body::-webkit-scrollbar-track {
  background: #ebe6d3;
}

body::-webkit-scrollbar-thumb {
  background: #83106a;
}

a {
  color: #83106a;
}

.main {
  width: 75%;
  margin-left: 20%;
}

@media screen and (max-width: 768px) {
  .main {
    width: 95%;
    margin: 1em;
  }
}

.container {
  width: 100%;
}

.experience-item {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: baseline;
  font-family: 'Crimson Text', serif;
  font-size: large;
  margin: 2em 0em;
}

.duration {
  width: 20%;
}

.title {
  width: 30%;
}

.description {
  width: 50%;
}

@media screen and (max-width: 768px) {
  .experience-item {
    flex-direction: column;
  }

  .duration,
  .title,
  .description {
    width: 100%;
  }
}

.intro {
  font-size: 8vmax;
  font-weight: 600;
}

.about {
  font-size: 1.5em;
  font-weight: normal;
}

.skills-container {
  margin-top: 5em;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
}

.section-title {
  font-size: 3em;
  font-weight: bold;
  width: 50%;
}

.accordion {
  width: 50%;
}

.accordion-item {
  cursor: pointer;
  padding: 1em;
  border-bottom: 1px solid #83106a;
}

.accordion-title {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}

@media screen and (max-width: 768px) {
  .skills-container {
    flex-direction: column;
  }

  .section-title,
  .accordion {
    width: 100%;
  }
}

.project-container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.project-index {
}

.project-title {
  font-size: 5vmax;
  font-weight: 600;
  padding: 0.5em 1em;
}

.project-content {
  font-family: 'Crimson Text', serif;
}

@media screen and (max-width: 768px) {
  .project-container {
    flex-direction: column;
  }

  .project-title {
    padding-top: 0;
  }

  .project-content {
    padding: 0.5em;
  }
}

.education-container {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: baseline;
  font-family: 'Crimson Text', serif;
  font-size: large;
  margin: 2em 0em;
}

.education-item {
  width: 33%;
}

.education-title {
  width: 80%;
  font-weight: bold;
}

.education-content {
  /* width: 50%; */
}

@media screen and (max-width: 768px) {
  .education-container {
    flex-direction: column;
  }

  .education-item {
    width: 100%;
  }
}

