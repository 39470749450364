.education-container {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: baseline;
  font-family: 'Crimson Text', serif;
  font-size: large;
  margin: 2em 0em;
}

.education-item {
  width: 33%;
}

.education-title {
  width: 80%;
  font-weight: bold;
}

.education-content {
  /* width: 50%; */
}

@media screen and (max-width: 768px) {
  .education-container {
    flex-direction: column;
  }

  .education-item {
    width: 100%;
  }
}
