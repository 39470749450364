.project-container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.project-index {
}

.project-title {
  font-size: 5vmax;
  font-weight: 600;
  padding: 0.5em 1em;
}

.project-content {
  font-family: 'Crimson Text', serif;
}

@media screen and (max-width: 768px) {
  .project-container {
    flex-direction: column;
  }

  .project-title {
    padding-top: 0;
  }

  .project-content {
    padding: 0.5em;
  }
}
