.main {
  width: 75%;
  margin-left: 20%;
}

@media screen and (max-width: 768px) {
  .main {
    width: 95%;
    margin: 1em;
  }
}
