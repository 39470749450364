@import url('https://fonts.cdnfonts.com/css/neue-haas-grotesk-display-pro');
@import url('https://fonts.cdnfonts.com/css/crimson-text');

.App {
  font-family: 'Neue Haas Grotesk Display Pro', sans-serif;
  background: #ebe6d3;
  color: #83106a;
  padding-bottom: 2em;
}

.logo {
  font-size: xx-large;
  font-weight: bold;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.main {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.top-bar {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 50px;
}

.social-link {
  padding: 10px;
  font-family: 'Crimson Text', serif;
}

.home-section {
  height: 100vh;
  width: 80%;
  position: relative;
  text-align: center;
}

.footer {
  font-size: 6vmax;
  font-weight: 600;
  padding: 1em;
}

body::-webkit-scrollbar {
  width: 10px; /* width of the entire scrollbar */
}

body::-webkit-scrollbar-track {
  background: #ebe6d3;
}

body::-webkit-scrollbar-thumb {
  background: #83106a;
}

a {
  color: #83106a;
}
