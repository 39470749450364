@import url('https://fonts.cdnfonts.com/css/crimson-text');

.container {
  width: 100%;
}

.experience-item {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: baseline;
  font-family: 'Crimson Text', serif;
  font-size: large;
  margin: 2em 0em;
}

.duration {
  width: 20%;
}

.title {
  width: 30%;
}

.description {
  width: 50%;
}

@media screen and (max-width: 768px) {
  .experience-item {
    flex-direction: column;
  }

  .duration,
  .title,
  .description {
    width: 100%;
  }
}
