.skills-container {
  margin-top: 5em;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
}

.section-title {
  font-size: 3em;
  font-weight: bold;
  width: 50%;
}

.accordion {
  width: 50%;
}

.accordion-item {
  cursor: pointer;
  padding: 1em;
  border-bottom: 1px solid #83106a;
}

.accordion-title {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}

@media screen and (max-width: 768px) {
  .skills-container {
    flex-direction: column;
  }

  .section-title,
  .accordion {
    width: 100%;
  }
}
